@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html,
body,
#root,
.App {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

*,
::after,
::before {
  box-sizing: border-box;
  margin: 0;
}
*:focus {
  outline: none;
}
:root {
  tab-size: 4;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #f2f5f7;
}

::-webkit-scrollbar-thumb {
  background-color: #c3d1da;
  border-radius: 6px;
  border: 0px solid #cfd8dc;
  border: 0px solid var(--scrollbarBG);
}

select {
  border: none;
}

html {
  line-height: 1.15;
  overflow-y: overlay;
  --scrollbarBG: #cfd8dc;
  --thumbBG: #90a4ae;
  scrollbar-width: thin;
  scrollbar-color: #90a4ae #cfd8dc;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}

body {
  font-size: 14px;
  font-family: 'Noto Sans JP', sans-serif;
  background-color: #fcfcfc;
  color: #222;
  padding-left: 250px;
  padding-top: 55px;
  overflow: overlay;
}

@media (max-width: 900px) {
  body {
    padding-left: 0px !important;
  }
}

hr {
  height: 0;
  color: inherit;
}

abbr[title] {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

b,
strong {
  font-weight: bolder;
}

code,
kbd,
pre,
samp {
  font-family: ui-monospace, SFMono-Regular, Consolas, 'Liberation Mono', Menlo,
    monospace;
  font-size: 1em;
}
small {
  font-size: 80%;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
table {
  text-indent: 0;
  border-color: inherit;
}
.camBtn {
  padding:10px 12px;
  background-color:#36a2eb;
  color:#000;
  border: 0.5px solid #000;
  margin:2px;
  border-radius: 10px;
}
.camBtn:hover {
  color:#222;
  cursor: pointer;
}
button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}
button,
select {
  text-transform: none;
}
[type='button'],
[type='reset'],
[type='submit'],
button {
  -webkit-appearance: button;
}
::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
:-moz-focusring {
  outline: 1px dotted ButtonText;
}
:-moz-ui-invalid {
  box-shadow: none;
}
legend {
  padding: 0;
}
progress {
  vertical-align: baseline;
}
::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}
[type='search'] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}
::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}
summary {
  display: list-item;
}
a {
  text-decoration: none;
  color: #222;
  transition: all 0.2s;
}
a:link {
  text-decoration: none;
}
a:visited {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}
a:active {
  text-decoration: none;
}
h2 {
  margin: 0;
  color: #333;
}

.bold {
  font-size: 16px;
  font-weight: bold;
}

.legend {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.legend .legend-title {
  text-align: left;
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 90%;
}
.legend .legend-scale ul {
  display: flex;
  text-align: center;
  justify-content: center;
  margin: 0;
  margin-bottom: 5px;
  padding: 0;
  list-style: none;
}
.legend .legend-scale ul li {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  list-style: none;
  margin: 0 auto 0.7em auto;
}
.legend ul.legend-labels li span {
  display: inline-block;
  height: 16px;
  width: 30px;
  margin: 0 5px;
  border: 2px solid #999;
}
.legend .legend-source {
  font-size: 70%;
  color: #999;
  clear: both;
}
.legend a {
  color: #777;
}

.legend-alt {
  display: flex;
  flex-direction: column;
}

.legend-alt .legend-alt-title {
  text-align: left;
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 90%;
}
.legend-alt .legend-alt-scale ul {
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: center;
  margin: 0;
  margin-bottom: 5px;
  padding: 0;
  list-style: none;
}
.legend-alt .legend-alt-scale ul li {
  font-size: 80%;
  list-style: none;
  margin: 20px 5px;
  margin-bottom: 0px;
}
.legend-alt ul.legend-alt-labels li span {
  display: inline-block;
  height: 16px;
  width: 30px;
  margin: 0 5px;
  border: 1px solid #999;
}
.legend-alt .legend-alt-source {
  font-size: 70%;
  color: #999;
  clear: both;
}
.legend-alt a {
  color: #777;
}

@media (max-width: 900px) {
  .legend-alt .legend-alt-scale ul {
    flex-direction: row;
    text-align: center;
    justify-content: center;
  }

  .legend-alt ul.legend-alt-labels li span {
    display: block;
  }
}

.search-container {
  padding: 0.8em 0em;
}

.customer-search-container {
  padding: 0.8em 0em;
}

@media (max-width: 800px) {
  .search-container {
    display: none;
  }
}

.search-container input {
  width: 230px;
  font-size: 1rem;
  height: 30px;
  line-height: 20px;
  border-radius: 15px;
  border: 1px solid #fafafa;
  padding: 0.4em 3em 0.4em 1em;
}

.customer-search-container input {
  width: 80%;
  font-size: 1rem;
  height: 30px;
  line-height: 20px;
  border-radius: 15px;
  border: 1px solid #fafafa;
  padding: 0.4em 3em 0.4em 1em;
}

.search-label {
  position: relative;
}

.customer-search-label {
  position: relative;
}

.search-icon {
  position: absolute;
  top: 4px;
  font-size: 16px;
  color: rgba(99, 171, 219, 0.9);
  right: 7px;
  border: none;
  background: none;
  cursor: pointer;
}


@media (max-width: 900px) {
  .search-icon {
    top: 4px;
  }
}

.search-results {
  position: absolute;
  background-color: #fafafa;
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.3);
  width: 380px;
  height: auto;
  top: 45px;
  right: 137px;
  z-index: 100;
  border-radius: 10px;
  padding: 1em;
}

.customer-search-results {
  background-color: #fafafa;
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.3);
  width: 800px;
  height: auto;
  z-index: 100;
  border-radius: 10px;
  padding: 1em;
}



.mobile-search-container {
  width: 90vw;
  margin: auto;
}
.mobile-search-container input {
  width: 100%;
  font-size: 1rem;
  border-radius: 15px;
  border: 1px solid #fafafa;
  padding: 0.3em 1em;
}

.hvr-grow {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  transition-duration: 0.3s;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
}
.hvr-grow:hover,
.hvr-grow:focus,
.hvr-grow:active {
  -webkit-transform: scale(1.03);
  transform: scale(1.03);
}

.fa-user-alt {
  background-color: #ececec;
  padding: 14.5px 0px 12px 15px;
}

.fa-lock {
  background-color: #ececec;
  padding: 15px 0px 12px 15px;
}

@-webkit-keyframes hvr-pulse {
  25% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  75% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}
@keyframes hvr-pulse {
  25% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
  75% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}

table.dashboard {
  background-color: #fff;
  min-width: 500px;
  width: auto;
  text-align: left;
  margin: 1em auto;
  border-spacing: 0;
  border-radius: 15px;
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.1);
}
table.dashboard td,
table.dashboard th {
  border-bottom: 1.5px solid #e9e9e9;
  padding: 12px 10px;
}
tr:last-child td {
  border-bottom: 0;
}
table.dashboard thead {
  text-align: center;
}
table.dashboard thead th {
  font-weight: bold;
  color: #ffffff;
  background-color: #4aabed;
}

table.dashboard td {
  max-width: 200px;
  width: auto;
  overflow-x: hidden;
}

table.dashboard tbody tr:hover {
  background-color: #f2f4f5;
}


 table.dashboard-scroll {
  background-color: #fff;
  text-align: left;
  margin: 1em auto;
  border-spacing: 0;
  border-radius: 15px;
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.1);
  height: 300px;
}
table.dashboard-scroll tbody {
  overflow-y: auto;
}
table.dashboard-scroll tbody thead {
  display: block;
}
 table.dashboard-scroll td,
 table.dashboard-scroll th {
  border-bottom: 1.5px solid #e9e9e9;
  padding: 12px 10px;
}
tr:last-child td {
  border-bottom: 0;
}
 table.dashboard-scroll thead {
  text-align: center;
}
 table.dashboard-scroll thead th {
  font-weight: bold;
  color: #ffffff;
  background-color: #4aabed;
}

 table.dashboard-scroll td {
  overflow-x: hidden;
}

 table.dashboard-scroll tbody tr:hover {
  background-color: #f2f4f5;
}

table.dashboard-mini {
  background-color: #fff;
  min-width: 300px;
  width: 300px;
  text-align: left;
  margin: 1em;
  border-spacing: 0;
  border-radius: 15px;
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.1);
}
table.dashboard-mini td,
table.dashboard-mini th {
  border-bottom: 1.5px solid #e9e9e9;
  padding: 12px 10px;
}
tr:last-child td {
  border-bottom: 0;
}
table.dashboard-mini thead {
  text-align: center;
}
table.dashboard-mini thead th {
  font-weight: bold;
  color: #ffffff;
  background-color: #4aabed;
}

table.dashboard-mini td {
  max-width: 200px;
  width: 200px;
  overflow-x: hidden;

}
table.dashboard-mini td:nth-child(2) {
  width: 100px;
  max-width: 100px;
}

table.dashboard-mini tbody tr:hover {
  background-color: #f2f4f5;
}

table.dashboard-mini tr td th {
  height: 50px;
  max-height: 50px !important;
  font-weight: bold;
}

table {
  border-spacing: 0;
}
th,
td {
  border: 0.1px solid rgba(255, 255, 255, 0);
  padding: 0.5em 1em;
}
thead tr:first-child th:first-child,
tbody:first-child tr:first-child td:first-child {
  border-top-left-radius: 15px;
}
thead tr:first-child th:last-child,
tbody:first-child tr:first-child td:last-child {
  border-top-right-radius: 15px;
}

thead + tbody tr:last-child td:first-child,
tfoot tr:last-child td:first-child {
  border-bottom-left-radius: 15px;
}
thead + tbody tr:last-child td:last-child,
tfoot tr:last-child td:last-child {
  border-bottom-right-radius: 15px;
}

thead + tbody tr td,
tfoot + tbody tr td,
tfoot tr td,
tbody + tbody tr td,
tr + tr td {
  border-top: 0;
}

tr th + th,
tr td + td {
  border-left: 0;
}

@media (max-width: 1000px) {
  table.dashboard {
    width: 100%;
    table-layout: fixed;
    background-color: #fcfcfc;
    min-width: 0px;
    text-align: left;
    white-space: wrap;
    overflow: hidden;
    border-radius: 0px;
  }

  table.dashboard tbody,
  table.dashboard thead {
    display: table-header-group;
    width: 100%;
    text-align: left;
  }

  table.dashboard td {
    white-space: wrap;
    overflow: hidden;
  }
  thead tr:first-child th:first-child,
  tbody:first-child tr:first-child td:first-child {
    border-top-left-radius: 0px;
  }
  thead tr:first-child th:last-child,
  tbody:first-child tr:first-child td:last-child {
    border-top-right-radius: 0px;
  }

  thead + tbody tr:last-child td:first-child,
  tfoot tr:last-child td:first-child {
    border-bottom-left-radius: 0px;
  }
  thead + tbody tr:last-child td:last-child,
  tfoot tr:last-child td:last-child {
    border-bottom-right-radius: 0px;
  }
}

.mobile-pop-up-menu {
  width: 100vw;
  height: 75vh;
  z-index: 30;
  bottom: 0;
  position: fixed;
  background-color: #fcfcfc;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 0 15px rgba(204, 204, 204, 1);
  position: absolute;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
}

.mobile-pop-up-menu div {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 2em;
  list-style: none;
}

.mobile-side-menu {
  width: 100vw;
  height: 100vh;
  z-index: 21;
  top: 0;
  right: 0;
  position: fixed;
  background-color: #fcfcfc;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 0 5px rgba(204, 204, 204, 0.8);
  position: absolute;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.mobile-side-menu ul {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: 2em;
  list-style: none;
}

.mobile-side-menu ul li {
  font-size: 1.7rem;
  margin: 0.5em 0;
  text-align: left;
}

@media (max-width: 900px) {
  -webkit-scrollbar {
    display: none;
    background: transparent;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  html {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;
  }
}

.topNavBar {
  display: flex;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 100%;
  position: fixed;
  z-index: 20;
  top: 0;
  right: 0;
  padding-left: 230px;
  align-items: center;
  flex-direction: row;
  background-color: #36a2eb;
  color: #fff;
  box-shadow: 0 0 5px rgba(33, 33, 33, 0.3);
}

.sideNavBar {
  height: 100%;
  width: 250px;
  position: fixed;
  z-index: 20;
  left: 0;
  top: 0;
  background-color: #f2f5f7;
  overflow-x: hidden;
  scrollbar-width: thin;
  --scrollbarBG: #cfd8dc;
  --thumbBG: #90a4ae;
  overflow-y: overlay;
  box-shadow: 0 0 5px rgba(33, 33, 33, 0.15);
}

@media (max-width: 800px) {
  .sideNavBar {
    width: 0px;
    display: none;
  }
}

